import { CheckBadgeIcon, PlusCircleIcon } from '@heroicons/react/16/solid'
import React from 'react'
import { Box } from '~/components/box'
import { type Results } from '~/domain/calc/products.server'
import { cn } from '~/utils/cn'
import { currency } from '~/utils/format'

type Props = {
	results: Results
}

function ProductResults({ results }: Props) {
	return (
		<div className="grid auto-cols-fr grid-cols-1 gap-6 sm:grid-cols-2">
			{Object.entries(results.products).map(([key, product]) => (
				<Box
					color="success"
					spacing="medium"
					key={key}
					className="relative overflow-hidden"
				>
					{product.special && (
						<div className="absolute top-0 right-0 h-16 w-16">
							<div className="bg-accent text-accent-content absolute top-[20px] right-[-55px] w-[170px] scale-75 rotate-45 transform py-0.5 text-center text-xs font-semibold">
								doporučujeme
							</div>
						</div>
					)}
					<div>
						<h3
							className={cn('text-2xl font-bold', product.special && 'mt-0!')}
						>
							{product.title}
						</h3>
						{product.subtitle && (
							<h4 className="text-neutral-500">{product.subtitle}</h4>
						)}
					</div>
					<div className="grid grid-cols-5 items-center">
						<div className="badge badge-outline badge-neutral badge-sm rounded-full">
							až
						</div>
						<div className="text-accent relative col-span-3 text-center text-4xl font-semibold whitespace-nowrap sm:text-3xl">
							{currency(product.potentialOffer)}
						</div>
					</div>
					<div className="space-y-1">
						{!results.sampleResults && (
							<>
								<div className="flex items-baseline">
									<PlusCircleIcon className="text-neutral my-1 mr-2 h-4 w-4 shrink-0" />
									<p>
										<strong>{currency(product.amounts.insulation)}</strong> na
										zateplení předem
									</p>
								</div>
								{product.amounts.heatSource > 0 && (
									<div className="flex items-baseline">
										<PlusCircleIcon className="text-neutral my-1 mr-2 h-4 w-4 shrink-0" />
										<p>
											až <strong>{currency(product.amounts.heatSource)}</strong>{' '}
											na výměnu vytápění předem
										</p>
									</div>
								)}
								{product.amounts.combiBonus > 0 && (
									<div className="flex items-baseline">
										<PlusCircleIcon className="text-neutral my-1 mr-2 h-4 w-4 shrink-0" />
										<p>
											<strong>{currency(product.amounts.combiBonus)}</strong> na
											kombinaci zateplení a výměna vytápění
										</p>
									</div>
								)}
								{product.amounts.childrenBonus > 0 && (
									<div className="flex items-baseline">
										<PlusCircleIcon className="text-neutral my-1 mr-2 h-4 w-4 shrink-0" />
										<p>
											<strong>{currency(product.amounts.childrenBonus)}</strong>{' '}
											za děti
										</p>
									</div>
								)}
								{results.regionWithBonus && (
									<div className="flex items-start">
										<CheckBadgeIcon className="text-accent my-1 mr-2 h-4 w-4 shrink-0" />
										<p>5% regionální bonus</p>
									</div>
								)}
								{product.lowIncomeOffer && (
									<div className="flex items-start">
										<CheckBadgeIcon className="text-accent my-1 mr-2 h-4 w-4 shrink-0" />
										<p>
											bonus <strong>110&nbsp;%</strong> pro důchodce a
											nízkopříjmové rodiny
										</p>
									</div>
								)}
							</>
						)}
						{results.sampleResults && product.bulletPoints && (
							<div>
								{product.bulletPoints.map((item, key) => (
									<div key={key}>
										<div className="flex items-start">
											<CheckBadgeIcon className="text-accent my-1 mr-2 h-4 w-4 shrink-0" />
											<p>{item}</p>
										</div>
									</div>
								))}
							</div>
						)}
					</div>
				</Box>
			))}
		</div>
	)
}

export default ProductResults
